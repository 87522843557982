import React from 'react';
import { useRag } from '../context/RagContext';

const DocumentTitles = () => {
  const { documents } = useRag();

  return (
    <div>
      <p className="text-lg mb-4">Verwendete Dokumente:</p>
      <ul className="space-y-2">
        {documents.map((doc, index) => (
          <div key={index} className="collapse collapse-arrow border-purple-500 border">
            <input type="checkbox" id={`collapse-${index}`} />
            <div className="collapse-title text-base">{doc.url}</div>
            <div className="collapse-content">
              <p>Dokumenten ID: {doc.id}, Absatz ID: {doc.passage_id}, Relevanz: {doc.score.toFixed(2)} <a className="underline text-purple-600 hover:text-purple-800" href={doc.url} target='_blank' rel="noreferrer">Link</a></p>
              <div className="divider"></div>
              <p>{doc.content}</p>
            </div>
          </div>
        ))}
      </ul>
    </div>
  );
};

export default DocumentTitles;
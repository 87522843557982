import React, { createContext, useContext, useState } from 'react';

// Define the type for a single document
type Document = {
  id: number;
  passage_id: number;
  score: number;
  url: string;
  content: string;
};

// Define the type for the context value
type RagContextType = {
  documents: Document[];
  addDocument: (document: Document) => void;
};

// Create the context with an undefined initial value
const RagContext = createContext<RagContextType | undefined>(undefined);

// Create a provider component
export const RagProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [documents, addDocuments] = useState<Document[]>([]);

  const addDocument = (document: Document) => {
    addDocuments((prevDocuments) => [...prevDocuments, document]);
  };

  return (
    <RagContext.Provider value={{ documents, addDocument }}>
      {children}
    </RagContext.Provider>
  );
};

// Create a custom hook to use the document context
export const useRag = () => {
  const context = useContext(RagContext);
  if (context === undefined) {
    throw new Error('useRag must be used within a RagContextProvider');
  }
  return context;
};
import React from 'react';

import Header from './components/Header';
import Footer from './components/Footer';

import Chat from './components/Chat'
import DocumentInfo from './components/DocumentInfo'

import { RagProvider } from './context/RagContext'

function App() {
  return (
      <div className="App flex flex-col h-screen w-full text-sm">
        <Header />
        <RagProvider>
          <div className="flex-grow flex gap-4 md:px-4 overflow-hidden">
            <div className="flex-1 w-3/4 md:border-2 md:border-purple-500 overflow-auto p-2 md:p-4 rounded-lg">
              <Chat />
            </div>
            <div className="hidden md:block flex-1 w-1/4 border-2 border-purple-500 overflow-auto p-4 rounded-lg">
              <DocumentInfo />
            </div>
          </div>
        </RagProvider>
        <Footer />
      </div>
  );
}

export default App;
